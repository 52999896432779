import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState = {
    pageLoadCount: 0,
    windowClickCount: 0
};

const incrementSlice = createSlice({
    name: 'incrementSlice',
    initialState,
    reducers: {
        incrementPageLoad: (state, action: PayloadAction<number>) => {
            return {...state, pageLoadCount: state.pageLoadCount + action.payload};
        },
        incrementWindowClick: (state, action: PayloadAction<number>) => {
            return {...state, windowClickCount: state.windowClickCount + action.payload};
        }
    }
});

export default incrementSlice;
